 import React from 'react';
import {
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
} from '@mui/material';

const Community = () => {
  const heading={
    fontWeight:"bold",
    fontSize:"25px"
  };
  return (
    <Container sx={{marginTop:"10%",backgroundColor:"whitesmoke",padding:"25px"}}>
    
      <Typography variant="h6" gutterBottom style={heading}>
        Program Details:
      </Typography>
      <List>
        <ListItem>
          <ListItemText primary="One student lead from every branch of every college." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Student Lead will form a team of 4-5 students from branch (irrespective of year)." />
        </ListItem>
        <ListItem>
          <ListItemText primary="These communities get guidance from our team, mentors, and industry connections." />
        </ListItem>
        <ListItem>
          <ListItemText primary="These communities act as a bridge between us and students of their college." />
        </ListItem>
      </List>

      <Divider sx={{ my: 4 }} />

      <Typography variant="h6" gutterBottom style={heading}>
        Eligibility for Community Lead:
      </Typography>
      <List>
        <ListItem>
          <ListItemText primary="Must be a 2nd or 3rd Year student from any branch." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Have leadership quality/had already hosted events in the past." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Is a tech-enthusiast and has knowledge of some technology/latest trends." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Have a passion to learn other technologies and share your knowledge with others." />
        </ListItem>
      </List>

      <Divider sx={{ my: 4 }} />

      <Typography variant="h6" gutterBottom style={heading}>
        Perks and Benefits:
      </Typography>
      <List>
        <ListItem>
          <ListItemText primary="Experience letter." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Recommendation Letter (For those who perform exceptionally well)." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Goodies & Gift Hampers." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Free access to online training and guidance from our team." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Paid internships in our company." />
        </ListItem>
      </List>

      <Divider sx={{ my: 4 }} />

      <Typography variant="body2" sx={{fontWeight:"700"}}>
        Note: Performance of all community leads will be assessed every 3 months, and replacements may be made based on several factors like performance,sincerity etc. The interest of other eligible batchmates will also be taken into consideration, as we want to give every student an opportunity to showcase their leadership skills.
      </Typography>
    </Container>
  );
};

export default Community;
